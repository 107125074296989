<template>
  <router-view />
</template>

<style>
html, body {
  margin: 0;;
  height: 100%;;
  overflow-x: hidden;
  overflow-y: hidden;
}
#app {
  font-family: 'Poppins';
  height: 100%;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */

</style>
