<template>
  <div class="
      w-56
      pt-20
      pl-4
      bg-gradient-to-t
      from-secondary
      to-secondary
      h-full
      fixed
      top-0
      z-30
      text-left text-primary
    ">
    <a @click="$emit('hideNav')" href="/b/board">
      <div v-bind:class="[
        $route.path == '/b/board' ? 'bg-primary text-secondary' : 'text-primary',
      ]" class="mt-8 rounded-lg cursor-pointer p-3 inline-block relative">
        <font-awesome-icon size="2x" class="w-7 inline align-middle" icon="chart-simple" />
        <span class="font-extrabold text-inherit text-xl align-top ml-2">Übersicht</span>
      </div>
    </a><br>
    <a v-if="user.currentTenant && user.currentTenantData.hr" @click="$emit('hideNav')" href="/b/archive">
      <div v-bind:class="[
        $route.path == '/b/archive' ? 'bg-primary text-secondary' : 'text-primary ',
      ]" class="mt-8 rounded-lg cursor-pointer p-3 inline-block relative">
        <font-awesome-icon size="2x" class="w-7 cursor-pointer inline" icon="archive" /><span class=" text-inherit font-bold text-xl align-top ml-2">Archiv</span>
      </div>
    </a><br>
    <a v-if="user.currentTenant && user.currentTenantData.hr" @click="$emit('hideNav')" href="/b/templates">
      <div v-bind:class="[
        $route.path == '/b/templates' ? 'bg-primary text-secondary' : 'text-primary',
      ]" class="mt-8 rounded-lg cursor-pointer p-3 inline-block relative">
        <font-awesome-icon size="2x" class="w-7 cursor-pointer inline" icon="envelope-open-text" /><span class=" text-inherit font-bold text-xl align-top ml-2">Vorlagen</span>
      </div>
    </a><br>
    <a @click="$emit('hideNav')" href="/b/org">
      <div v-bind:class="[
        $route.path == '/b/org' || $route.path == '/b/settings' ? 'bg-primary text-secondary' : 'text-primary',
      ]" class="mt-8 rounded-lg cursor-pointer p-3 inline-block relative">
        <font-awesome-icon size="2x" class="w-7 cursor-pointer inline" icon="building" /><span class=" text-inherit font-bold text-xl align-top ml-2">Einstellungen</span>
      </div>
    </a>
    <div class="absolute left-0 bottom-0 pb-4 w-full text-center">
    <button class="text-primary border-primary" @click="logout()">Ausloggen</button>
  </div>
  </div>
  
</template>
<script>
import Auth from '@/API/Auth';
export default {
  name: "TenantTile",
  computed: {},
  emits: ["hideNav"],
  props: {
    user: Object
  },
  methods: {
    logout() {
      Auth.logout(res => {
        window.location = "/login";
      })
    },
  },
};
</script>