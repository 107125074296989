<template>
  <div class="w-full h-full relative flex flex-col p-4 grow min-w-0">
    <Transition>
      <top-banner :mode="bannerMode" :text="bannerText" v-if="bannerText" />
    </Transition>
    <file-viewer
      v-if="viewFile"
      :file="currentFile"
      @closeFileViewer="viewFile = false"
    />
    <div
      v-bind:class="{
        'overflow-hidden flex flex-col': !tenant.aboutus,
        'overflow-y-auto': tenant.aboutus,
      }"
      class=""
    >
      <div class="">
        <div v-if="tenant.aboutus">
          <span class="font-semibold text-2xl text-gray-700"
            >Über {{ tenant.name }}</span
          ><br />
          <pre
            class="bg-gray-100 rounded-lg px-5 py-2 inline-block whitespace-pre-line break-normal font-bold"
            >{{ tenant.aboutus }}</pre
          ><br>
          <span
            class="font-semibold text-2xl text-gray-700 mt-3 inline-block"
            >{{ title }}</span
          >
        </div>
        <span
          v-else
          class="font-semibold text-2xl text-gray-700 mt-3 inline-block"
          >{{ title }}</span
        >
      </div>
      <div id="jobs" class="overflow-y-auto">
        <div v-for="job in jobs" :key="job" class="inline-block p-1">
          <JobTile
            :job="job"
            :fields="{
              details:false,
              showDate: true,
              weekhours: true,
              location: true,
              portal: true,
              department: true,
              applications: false,
              salaryTo: false,
            }"
            @click="
              showJobDetails = true;
              currentJob = job;
            "
          />
        </div>
      </div>
    </div>
    <JobApplication
      v-if="showJobDetails && currentJob"
      :job="currentJob"
      :user="user"
      @closeJobDetails="showJobDetails = false"
      @applicationSaved="applicationSaved()"
    />
  </div>
</template>

<script>
import TopBanner from "@/components/TopBanner.vue";
import FileViewer from "@/components/FileViewer.vue";
import API from "@/API/Private";
import JobTile from "@/components/tiles/JobTileV2.vue";
import JobApplication from "@/components/private/JobApplicationModal.vue";

// @ is an alias to /src

export default {
  name: "TenantJobsView",
  components: {
    TopBanner,
    FileViewer,
    JobTile,
    JobApplication,
  },
  props: {
    user: Object,
    slug: String,
  },
  data() {
    return {
      viewFile: false,
      bannerText: "",
      bannerMode: "success",
      defaulttitle: "Kein Unternehmen unter dieser URL gefunden",
      title: "Kein Unternehmen gefunden",
      jobs: [],
      showJobDetails: true,
      currentJob: false,
      window: window,
      tenant: {},
    };
  },

  methods: {
    applicationSaved() {
      this.showJobDetails = false;
      this.showBanner("Bewerbung hinzugefügt");
    },
    showBanner(text, error = false) {
      this.bannerMode = error ? "error" : "success";
      this.bannerText = text;
      let vm = this;
      setTimeout(() => {
        vm.bannerText = "";
      }, 5000);
    },
  },
  created: function () {
    let vm = this;
    
    API.getTenantJobs(this.slug, (tenant) => {
      if (tenant) {
        vm.tenant = tenant;
        document.cookie = "lastCompany=" + this.slug+"; path=/";
        document.cookie = "lastCompanyName=" + tenant.name+"; path=/";
        if (tenant.jobs.length > 0) {
          vm.title = "Jobs bei " + tenant.name;
        } else {
          vm.title = tenant.name + " hat aktuell keine offenen Stellen";
        }
        vm.jobs = tenant.jobs;
        if (vm.$route.query.j) {
          vm.currentJob = vm.jobs.filter(
            (item) => item.id == vm.$route.query.j
          )[0];
          delete vm.$route.query.j;
          history.pushState({}, null, "/p/" + vm.slug);
        }
      }
    });
  },
};
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 4s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}
</style>