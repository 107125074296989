<template>
  <div class="">
    <div class="relative">
      <div class="inline-block w-full">
        <span v-if="jobs.length==0" class="font-semibold text-2xl text-gray-700 mt-3 inline-block">{{ title }}</span>
      <div v-else id="jobs">
        <div v-for="job in jobs" :key="job">
          <JobTile
            :job="job"
            :fields="{
              'showDate': true,
              'weekhours': true,
              'location': true,
              'portal': true,
              'department': true,
              'applications': false,
              'salaryTo': false,
            }"
            @click="window.open('/p/'+tenant.slug+'/?j='+job.id)"
          />
        </div>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import API from "@/API/Private";
import JobTile from "@/components/tiles/JobTileV2.vue";

// @ is an alias to /src

export default {
  name: "TenantJobsView",
  components: {
    JobTile,
  },
  props: {
    user: Object,
    slug: String,
  },
  data() {
    return {
      title: "",
      jobs: [],
      window: window,
      tenant: {},
    };
  },

  methods: {},
  created: function () {
    let vm = this;
    API.getTenantJobs(this.$route.params.slug, (tenant) => {
      if (tenant) {
        vm.tenant = tenant;
        if (tenant.jobs.length == 0) {
          vm.title = "Es gibt aktuell leider keine offenen Stellen";
        }
        vm.jobs = tenant.jobs;
        if (vm.$route.query.j) {
          vm.currentJob = vm.jobs.filter(
            (item) => item.id == vm.$route.query.j
          )[0];
          delete vm.$route.query.j;
          history.pushState({}, null, "/p/" + this.$route.params.slug);
        }
      }
    });
  },
};
</script>
<style scoped>
html, body{
  height: unset;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 4s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

</style>