<template>
  <div class="w-full h-full relative p-4 overflow-y-auto">
    <file-viewer
      v-if="viewFile"
      :file="file"
      @closeFileViewer="viewFile = false"
    />
    <span
      class="font-semibold text-2xl border-b-2 text-gray-700 mr-3 cursor-pointer"
      onclick="window.location='/b/org'"
      >Organisationseinstellungen</span
    >
    <span class="font-semibold text-2xl text-primary border-b-2 border-primary"
      >Persönliche Einstellungen</span
    >

    <div class="pt-4 sm:p-5 h-full">
      <div
        class="w-full h-full "
      >
        <div
          class="lg:pb-4 lg:border-r-2 border-gray-300 pr-3 inline-block"
        >
          <span
            @click="mode = 'notifications'"
            v-bind:class="[
              mode == 'notifications' ? 'text-primary underline' : '',
            ]"
            class="mt-3 cursor-pointer block bold text-xl"
            >Benachrichtigungen</span
          >
        </div>
        <div class="text-left align-top inline-block relative bg-gray-100 md:pl-4">
          <div
            v-if="mode == 'notifications'"
            class="inline-block align-top text-left"
          >
            <span class="text-2xl font-bold"
              >Benachrichtigungseinstellungen</span
            ><br><span>Gib an, bei welchen Ereignissen du per Mail benachrichtigt werden möchtest.</span>
            <div class="grid xl:grid-cols-2 mt-2">
              <div>
                <input type="checkbox" @change="updateNotificationSettings()" v-model="editableUser.notificationSettings.newApplications" id="newApplications" /><label
                  for="newApplications"
                  >Neue Bewerbung</label
                >
              </div>
              <div>
                <input type="checkbox" @change="updateNotificationSettings()" v-model="editableUser.notificationSettings.newComments" id="newComments" /><label
                  for="newComments"
                  >Neuer Kommentar</label
                >
              </div>
              <div>
                <input type="checkbox" @change="updateNotificationSettings()" v-model="editableUser.notificationSettings.applicationAssigned" id="applicationAssigned" /><label
                  for="applicationAssigned"
                  >Bewerbung zugewiesen</label
                >
              </div>
              <div>
                <input type="checkbox" @change="updateNotificationSettings()" v-model="editableUser.notificationSettings.movedToInterview" id="movedToInterview" /><label
                  for="movedToInterview"
                  >In Spalte "Interview" verschoben</label
                >
              </div>
              <div>
                <input type="checkbox" @change="updateNotificationSettings()" v-model="editableUser.notificationSettings.movedToOffer" id="movedToOffer" /><label
                  for="movedToOffer"
                  >In Spalte "Angebot" verschoben</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useCookies } from "vue3-cookies";
import API from "@/API/Business";
import Encryption from "@/Encryption";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "OrganizationView",
  components: {},
  props: {
    user: Object,
  },
  data() {
    return {
      mode: "notifications",
      editableUser: {},
    };
  },
  methods: {
    updateNotificationSettings() {
      let vm=this;
      API.updateUser(this.editableUser, (user) => {
        this.editableUser = JSON.parse(JSON.stringify(user));
      })
    }
  },
  created: function () {
    this.editableUser = JSON.parse(JSON.stringify(this.user));
  },
};
</script>
<style scoped>
input {
  border-bottom: 1px solid black;
}
</style>