<template>
  <div class="w-full">
    <span v-if="errors.name" class="font-semibold text-red-500">Vor- und Nachname (Pflichtfeld)</span>
    <span v-else class="font-semibold">Vor- und Nachname</span>
    <input class="w-full bg-gray-100 py-1 px-2 mt-1" placeholder="Vor- und Nachname" v-model="details.name" />
    <div class="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2">
      <div class="pt-3 pr-2">
        <span v-if="errors.mail" class="font-semibold text-red-500">E-Mail (Pflichtfeld)</span>
        <span v-else class="block font-semibold">E-Mail</span>
        <input class="w-full bg-gray-100 py-1 px-2 mt-1" placeholder="E-Mail" v-model="details.mail" />
      </div>
      <div class="pt-3 pr-2">
        <span v-if="errors.phone" class="font-semibold text-red-500">Telefonnummer (Pflichtfeld)</span>
        <span v-else class="block font-semibold">Telefonnummer</span>
        <input class="w-full bg-gray-100 py-1 px-2 mt-1" placeholder="Telefonnummer" v-model="details.phone" />
      </div>
    </div>
    <div class="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2">
      <div class="pt-3 pr-2">
        <span v-if="errors.startDate" class="font-semibold text-red-500">Frühester Start (Pflichtfeld)</span>
        <span v-else class="block font-semibold">Frühester Start</span>
        <input type="date" class="w-full bg-gray-100 py-1 px-2 mt-1" placeholder="Link zum Bewerberportal" v-model="details.startDate" />
      </div>
      <div class="pt-3 pr-2">
        <span v-if="errors.salary" class="font-semibold text-red-500">Gehaltsvorstellungen (Pflichtfeld)</span>
        <span v-else class="block font-semibold">Gehaltsvorstellungen</span>
        <input class="w-5/12 bg-gray-100 py-1 px-2 mt-1 text-center" placeholder="35000" v-model="details.salary" />
        <b>€</b>
      </div>
      <div class="pt-3 pr-2">
        <span v-if="errors.weekhours" class="font-semibold text-red-500">Wochenstunden (Pflichtfeld)</span>
        <span v-else class="block font-semibold">Wochenstunden</span>
        <input class="w-20 bg-gray-100 py-1 mr-1 px-2 mt-1 text-center" placeholder="40" type="number" v-model="details.weekhours" />
        <b>h/Woche</b>
      </div>
      <div class="pt-3 pr-2">
        <span v-if="errors.city" class="font-semibold text-red-500">Wohnort (Pflichtfeld)</span>
        <span v-else class="block font-semibold">Wohnort</span>
        <input class="w-full bg-gray-100 py-1 px-2 mt-1 text-left" placeholder="Köln" v-model="details.city" />
      </div>
      <div class="pt-3 pr-2">
        <span v-if="errors.birthdate" class="font-semibold text-red-500">Geburtsdatum (Pflichtfeld)</span>
        <span v-else class="block font-semibold">Geburtsdatum</span>
        <input class="w-full bg-gray-100 py-1 px-2 mt-1 text-left" type="date" v-model="details.birthdate" />
      </div>
    </div>
  </div>

</template>

<script>
export default {
  expose: ["setDetails", "validateFields"],
  props: {
    application: Object
  },
  data() {
    return {
      details: {},
      errors: {},
    };
  },
  methods: {
    validateFields(fields = null) {
      let valid = true;
      console.log(fields)
      if (fields) {
        for (let i = 0; i < fields.length; i++) {
          console.log("" + fields[i])
          if (!this.details["" + fields[i]]) {
            this.errors[fields[i]] = true;
            valid = false;
          }
        }
      }
      return valid;
    },
    setDetails(application) {
      application.name = this.details.name
      application.mail = this.details.mail
      application.salary = this.details.salary
      application.startDate = this.details.startDate
      application.city = this.details.city
      application.phone = this.details.phone
      application.birthdate = this.details.birthdate
      application.weekhours = this.details.weekhours
      return application;
    },
  },
  created: function () {
    this.details = this.application
  }
};
</script>

<style scoped></style>
