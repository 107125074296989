BUSINESSTOKEN = 1
PRIVATETOKEN = 2

function authGET(path, callback) {
    var req = new XMLHttpRequest()
    req.open('GET', process.env.VUE_APP_AUTH_URL + path)
    req.withCredentials = true  
    req.addEventListener('load', function (event) {
        if (req.status == 204) {
            callback(true)
        } else {
            try {
                callback(JSON.parse(req.responseText))
            } catch (e) {
                callback(false)
            }
        }
    })
    req.send()
}

function loginWithCode(type, provider, code, redirectOrMail, callback) {
    var req = new XMLHttpRequest()
    req.open("POST", process.env.VUE_APP_AUTH_URL + '/login')
    req.setRequestHeader('content-type', 'application/json')
    req.withCredentials=true
    req.addEventListener('load', function (event) {
        try {
            console.log(req)
            console.log(event)
            callback(JSON.parse(req.responseText))
        } catch (e) {
            callback(false)
        }
    })
    req.send(JSON.stringify({
        type: type,
        provider: provider,
        code: code,
        redirectOrMail: redirectOrMail
    }))
}

function checkAuth(callback) {
    authGET("/authenticate", (success) => {
        if (success) {
            callback(success)
        } else {
            callback(false)
        }
    })
}
function logout(callback) {
    authGET("/logout", (success) => {
        if (success) {
            callback(success)
        } else {
            callback(false)
        }
    })
}

function getCalc(callback) {
    var req = new XMLHttpRequest()
    req.open("GET", process.env.VUE_APP_AUTH_URL + '/calc')
    req.addEventListener('load', function (event) {
        try {
            callback(JSON.parse(req.responseText))
        } catch (e) {
            callback(false)
        }
    })
    req.send()
}

function getMailCode(mail, uid, calc, callback) {
    var req = new XMLHttpRequest()
    req.open("POST", process.env.VUE_APP_AUTH_URL + '/mailcode')
    req.setRequestHeader('content-type', 'application/json')
    req.addEventListener('load', function (event) {
        try {
            callback(JSON.parse(req.responseText))
        } catch (e) {
            callback(false)
        }
    })
    req.send(JSON.stringify({
        mail: mail,
        uid: uid,
        calc: calc
    }))
}

function switchSpace(space, callback) {
    var req = new XMLHttpRequest()
    req.open("PUT", process.env.VUE_APP_AUTH_URL + '/switchSpace')
    req.setRequestHeader('content-type', 'application/json')
    req.withCredentials = true  
    req.addEventListener('load', function (event) {
        try {
            callback(JSON.parse(req.responseText))
        } catch (e) {
            callback(false)
        }
    })
    req.send(JSON.stringify({
        space: space
    }))
}

module.exports = {
    logout: logout,
    getCalc: getCalc,
    getMailCode: getMailCode,
    switchSpace: switchSpace,
    checkAuth: checkAuth,
    loginWithCode: loginWithCode,
    BUSINESSTOKEN: BUSINESSTOKEN,
    PRIVATETOKEN: PRIVATETOKEN
}