

module.exports = {
    formatDate: function (date){
        if(!date.includes("T") && date.split("-").length<=2) return false
        string=date.replace(":00Z","");
        let tdp=string.split("T")
        let dp=tdp[0].split("-")
        return dp[2]+"."+dp[1]+(tdp[1]?" "+tdp[1]:"")
    },
}