<template>
  <div class="w-full h-20 fixed top-0 bg-white z-30" style="box-shadow: 0px 2px 10px lightgray">
    <div class="inline-block sm:hidden align-top mt-5">
      <font-awesome-icon class="cursor-pointer text-4xl pl-5" icon="bars" @click="toggleNav()" />
    </div>
    <div class="text-center mt-1 inline-block">
      <div v-if="!logo" class="px-5 pt-2">
        <img class="px-2 w-20 align-middle -mt-5 text-primary hidden sm:inline-block" src="@/static/LogoOrange.png" />
        <span class="font-extrabold text-5xl text-secondary">Apply</span>
        <span class="font-extrabold text-5xl text-primary">hr</span>
      </div>
      <div v-else class="px-4">
        <img class="h-16" :src="'data:image/jpg;base64,' + logo" />
      </div>
    </div>
    <div class="inline-block align-top mt-4">
      <div class="border-2 border-solid border-gray-300 rounded-full p-2 box-border hidden lg:block">
        <font-awesome-icon class="px-2 text-gray-400" icon="search" />
        <input @focus="showEntries = true" @blur="closeSearch()" v-model="filter" class="border-0 w-96 focus:no-underline no-underline bg-white py-0 mt-0" placeholder="Suche" />
      </div>
      <div v-if="showEntries" class="ml-5 p-2 text-center bg-white rounded-lg shadow-lg max-w-lg max-h-96 overflow-auto">
        <template v-for="entry in filteredEntries" :key="entry">
          <div class="w-full border-b border-b-primary last:border-b-0 text-left p-3 hover:bg-primary/10 cursor-pointer" @click="clickEntry(entry)">
            <div v-if="entry.type == 'job'">
              <span class="text-xl font-bold">{{ entry.jobtitle }}</span>
              <div class="ml-2 rounded-full bg-primary/25 py-0.5 px-3 inline-block float-right">
                <span class="text-primary">Stellenanzeige</span>
              </div>
            </div>
            <div v-if="entry.type == 'application'">
              <span class="text-xl font-bold">{{ entry.companyName }}</span><br />
              <div class="ml-2 rounded-full bg-primary/25 py-0.5 px-3 inline-block float-right">
                <span class="text-primary">Bewerbung</span>
              </div>
              <span class="text font-bold">{{ entry.jobtitle }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="float-right mt-4 mr-4">
      <div class="hidden sm:inline-block align-middle mr-4">
        <span v-if="editableUser.name" class="text-secondary">Hallo,<br /><b>{{ editableUser.name }}</b></span>
        <span v-else class="text-secondary">Hallo,<br /><b>{{ mailname }}</b></span>
      </div>
      <font-awesome-icon @click="showlogout = !showlogout" size="2x" class="px-2 cursor-pointer text-secondary align-top mt-1" icon="user" />
      <div v-if="showlogout" class="z-40 bg-white absolute top-14 right-0 md:right-20 p-5 whitespace-nowrap">

        <button v-if="user.mail" @click="logout()" class="bg-white my-2">
          Ausloggen
        </button>
        <button v-else @click="login()" class="bg-white">Einloggen</button>
      </div>
    </div>
  </div>
  <div v-if="navVisible" class="absolute top-20 z-20 bg-white w-full shadow-lg p-4" @click="toggleNav()">
    <router-link v-if="lastCompany" :to="'/p/' + lastCompany">
      <div v-bind:class="[
        $route.path == '/p/' + lastCompany ? 'bg-primary text-secondary' : '',
      ]" class="rounded-lg cursor-pointer p-3 relative">
        <font-awesome-icon class="inline align-middle text-xl pr-4" icon="building" /><span class="font-bold">{{lastCompanyName}}</span>
      </div>
    </router-link>
    <router-link to="/c/overview">
      <div v-bind:class="[
        $route.path == '/c/overview' ? 'bg-primary text-secondary' : '',
      ]" class="rounded-lg cursor-pointer p-3 relative">
        <font-awesome-icon class="inline align-middle text-xl pr-4" icon="chart-simple" /><span class="font-bold">Deine Bewerbungen</span>
      </div>
    </router-link>
    <router-link to="/c/docs">
      <div v-bind:class="[
        $route.path == '/c/docs' ? 'bg-primary text-secondary' : '',
      ]" class="mt-3 rounded-lg cursor-pointer p-3 relative">
        <font-awesome-icon class="cursor-pointer inline text-xl pr-4" icon="file-invoice" /><span class="font-bold">Deine Dokumente</span>
      </div>
    </router-link>
  </div>
</template>
<script>
import { useCookies } from "vue3-cookies";
import Auth from "@/API/Auth";
import API from "@/API/Business";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
    user: Object,
    searchEntries: Array,
    slug: String,
  },
  data() {
    return {
      lastCompany: "",
      lastCompanyName: "",
      showlogout: false,
      logo: false,
      window: window,
      editableUser: this.user,
      showEntries: false,
      filter: "",
      navVisible: false,
    };
  },
  methods: {
    toggleNav() {
      this.navVisible = !this.navVisible;
    },
    closeSearch() {
      setTimeout(() => (this.showEntries = false), 200);
    },
    switchSpace() {
      Auth.switchSpace("business", () => {
        window.location = "/";
      });
    },
    clickEntry(entry) {
      if (entry.type == "application")
        window.location = "/c/overview?a=" + entry.id;
      else if (entry.type == "job")
        window.location = "/p/" + entry.slug + "?j=" + entry.id;
    },
    logout() {
      Auth.logout((success) => {
        window.location = "/login";
      });
    },
    getCookie(name) {
      name = name + '='
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');

      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return ""
    },
  },
  computed: {
    mailname: function () {
      return this.user.mail.split("@")[0];
    },
    filteredEntries: function () {
      let vm = this;
      return this.searchEntries.filter((entry) => {
        let fp = vm.filter.split(" ");
        for (let i = 0; i < fp.length; i++) {
          if (!(entry.strings && entry.strings.includes(fp[i].toLowerCase()))) {
            return false;
          }
        }
        return true;
      });
    },
  },
  created: function () {
    if (this.slug) {
      API.getLogo(this.slug, (logo) => {
        this.logo = logo.logo;
      });
    }
    this.lastCompany = this.getCookie("lastCompany");
    this.lastCompanyName = this.getCookie("lastCompanyName");
  },
};
</script>