<template>
  <div class="inline-block align-top w-full mt-4 lg:mt-0 h-full text-left overflow-y-auto">
    <span class="inline-block text-2xl font-bold">Öffentliche Stellen</span>

    <div class="inline-block bg-white rounded-2xl shadow-lg w-full p-5 mt-3 overflow-y-auto">
      <div class="mb-4">
        <span class="font-bold text-2xl mb-2">URL für öffentliche Stellen</span><br />
        <input type="text" class="w-auto text-right" v-model="slug" @input="checkSlug()" @change="resetSlug()">.applyhr.de/
        <span v-if="slugTaken" class="inline text-red-500 ml-4 font-bold">Diese URL ist bereits belegt</span>
        <button @click="updateSlug()" v-if="!slugTaken && slug != user.currentTenantData.slug && slug" class="inline-block ml-10 text-primary border-primary mb-3 mt-5 mr-5">
          Speichern
        </button>
      </div>
      <div class="">
        <span class="font-bold text-2xl mb-2">Logo</span><br />
        <div v-if="!file">
          <div class="cursor-pointer p-4 inline-block w-96 h-40 rounded-xl shadow-selected" @click="$refs.input.click()">
            <div class="h-full align-middle text-6xl relative text-center min-h-36">
              <span class="text-2xl top-8">Logo hinzufügen</span><br />
              <font-awesome-icon icon="image" class="text-primary top-20" />
            </div>

            <input ref="input" class="w-full" id="fileinput" type="file" @change="uploadLogo()" hidden accept=".jpg" />
          </div>

        </div>
        <div v-else>
          <img class="h-12" :src="'data:image/jpg;base64,' + file" />
        </div>
      </div>
      <div class="inline-block align-top mt-10">
        <button @click="saveLogo()" v-if="changed" class="inline-block ml-10 text-primary border-primary mb-3 mt-5 mr-5">
          Speichern
        </button>
        <button @click="deleteLogo()" v-else-if="file" class="inline-block ml-10 bad mb-3 mt-5 mr-5">
          Löschen
        </button>
      </div>
      <div class="mt-4">
        <span class="font-bold text-2xl">Preview</span>
        <div class="w-full h-[28rem] overflow-y-scroll">
          <iframe ref="iframe" :src="'/p/' + user.currentTenantData.slug" class="w-full h-full overflow-y-scroll" style="zoom: 0.75" />
        </div>
      </div>
      <div class="mt-4">
        <span class="font-bold text-2xl mb-2">In Website einbetten</span><br />
        Über den folgenden HTML-Code können die aktuell als "Veröffentlicht" markierten Stellen auf einer Website eingebettet werden:
        <span class="w-full mt-3 inline-block font-bold">Basis</span>

        <code class="w-full inline-block bg-gray-100 mt-5">
            &lt;iframe src="https://app.applyhr.de/iframes/{{ user.currentTenantData.slug }}"&gt;&lt;/iframe&gt;
            </code>
        <span class="w-full mt-3 inline-block font-bold">Volle Breite, Höhe je nach Anzahl der Jobs</span>

        <code class="w-full inline-block bg-gray-100 mt-5 break-all">
            &lt;iframe style="width: 100%;" onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));' src="https://app.applyhr.de/iframes/{{ user.currentTenantData.slug }}"&gt;&lt;/iframe&gt;
            </code>
      </div>
    </div>
  </div>
</template>
<script>
import Encryption from "@/Encryption";
import API from "../../API/Business"
export default {
  props: {
    user: Object,
  },
  components: {},
  data() {
    return {
      file: false,
      details: {},
      showAddFileModal: false,
      changed: false,
      slug: "",
      slugTaken: false,
    };
  },
  methods: {
    uploadLogo() {
      let vm = this;
      let fr = new FileReader();
      this.$refs.input.files.forEach((file) => {
        fr.readAsArrayBuffer(file);
        fr.onload = function () {
          var data = fr.result;
          vm.file = Encryption.arrayBufferToBase64(data);
          console.log(this.file);
        };
      });
      this.changed = true;
    },
    saveLogo() {
      API.addLogo(this.file, () => {
        this.changed = false
        this.$refs.iframe.src = this.$refs.iframe.src
      })
    },
    resetSlug(){
      if(this.slug == "") this.slug=this.user.currentTenantData.slug
    },
    checkSlug() {
      if(this.slug=="") return
      API.checkTenantSlug(this.slug, (res) => {
        if(res && res.status=="free"){
          this.slugTaken = false
        }else{
          this.slugTaken = true
        }
      })
    },
    updateSlug(){
      API.updateTenant({id: this.user.currentTenant, slug: this.slug}, (res) => {
        if(res){
          this.$emit("refreshUser")
        }
      })
    },
    deleteLogo() {
      API.deleteLogo(() => {
        this.file = false
        this.$refs.iframe.src = this.$refs.iframe.src
      })
    }
  },
  created: function () {
    if (this.user.currentTenantData) {
      if (this.user.currentTenantData.logo) {
        API.getLogo(this.user.currentTenantData.slug, logo => {
          this.file = logo.logo
        })
      }
      this.slug = this.user.currentTenantData.slug
    }

  },
};
</script>

<style scoped></style>
