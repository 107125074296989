<template>
  <div class="inline-block align-top w-full mt-4 sm:mt-0 h-full text-left overflow-y-auto">
    <span class="inline-block text-2xl font-bold">Mailbox-Integration</span>
    <div class="bg-white rounded-lg p-4 mt-3 w-full inline-block">
      <button v-if="!mailbox.mailboxEnabled" class="text-red-500 border-red-500" @click="setMailboxstatus(true)">Integration mit E-Mail Postfach für 0,2 €/Tag aktivieren</button>
      <div v-else>
        <div class="">
          <label class="block">Art der Integration</label>
          <select v-model="mailbox.mailboxType" @change="setMailboxType()" class="w-auto">
            <option value="">Bitte auswählen</option>
            <option value="applyhr">ApplyHR</option>
            <option value="custom">Eigenes Postfach</option>
          </select>
        </div>
        <div v-if="mailbox.mailboxType == 'custom'">
          <span class="font-bold text-lg mt-4">Eigenes Postfach</span>
          <span v-if="resultMessage" class="px-2 py-1 rounded bg-red-400 font-bold" :class="{ 'bg-green-400': resultMessage == 'Verbindung erfolgreich' }">{{ resultMessage }}</span>
          <span>Bei der Mailboxintegration ruft ApplyHR regelmäßig die E-Mails aus dem angegebenen Postfach ab und importiert eingegangene Bewerbungen. Name, E-Mail Adresse, Telefonnummer und andere Daten werden automatisch erkannt und
            Anhänge
            der Bewerbung angefügt.</span>
          <div class="grid grid-cols-4 mt-2">
            <div class="col-span-3">
              <label>IMAP-Server:</label>
              <input @input="dataChanged = true" v-model="mailbox.mailboxserver" placeholder="POP3-Server">
            </div>
            <div class="">
              <label>Port</label>
              <input @input="dataChanged = true" v-model="mailbox.mailboxport" type="number" placeholder="Port">
            </div>
            <div class="col-span-2 mt-2">
              <label>Username</label>
              <input @input="dataChanged = true" v-model="mailbox.mailboxusername" placeholder="Username">
            </div>
            <div class="col-span-2 mt-2">
              <label>Passwort</label>
              <input @input="dataChanged = true" v-model="mailbox.mailboxpassword" type="password" placeholder="Passwort">
            </div>
          </div>
          <label class="mt-3 inline-block">Ignoriere Mails in diesem Postfach, die vor dem angegebenen Datum eingegangen sind:</label>
          <br><input type="date" v-model="mailbox.mailboxIgnoreBefore" class="w-56">
          <div class="w-full text-right mt-4">
            <button v-if="mailbox.mailboxEnabled" class="text-red-500 border-red-500 mr-2" @click="setMailboxstatus(false)">Integration deaktivieren</button>
            <button class="mr-2 text-yellow-500 border-yellow-500 relative min-h-fit" @click="testMailboxConnection()">
              <div v-if="loading == 'testConnection'" class="lds-dual-ring"></div><span class="text-yellow-500" :class="{ 'invisible': loading == 'testConnection' }">Verbindung testen</span>
            </button>
            <button @click="saveMailbox()" v-if="dataChanged" :class="{ 'text-green-500 border-green-500': !dataChanged }">Speichern</button>
          </div>
        </div>
        <div v-if="mailbox.mailboxType == 'applyhr'">
          <span class="font-bold text-lg mt-4">ApplyHR</span><br>
          <span>Leiten Sie ihre E-Mails einfach an <span class="bg-gray-200 px-2 py-1 font-bold">{{ user.currentTenantData.slug }}@applyhr.de</span> weiter. Diese werden automatisch importiert und ihren Stellen zugeordnet.</span>
          <label>Originale E-Mail-Adresse, welche an oben genannte Applyhr-Adresse weiterleitet</label><br>
          <input v-model="mailbox.mailboxForwarder" placeholder="bspw. bewerbung@deinefirma.de" class="w-1/2 mr-2">
          <button v-if="mailbox.mailboxForwarder != mailboxForwarderSaved" @click="saveMailbox()">Speichern</button>
        </div>
      </div>
    </div>
    <div v-if="mailbox.mailboxEnabled">
      <span class="inline-block text-2xl font-bold mt-4">KI-Integration (Beta)</span>
      <div class="bg-white rounded-lg p-4 mt-3 w-full inline-block">
        <div class="mt-2">
          <span class="inline-block text-lg font-bold">KI-Ersteinschätzung</span>
          <span>Der KI-Assistent schaut sich die Anforderungen der Stelle an, die im oberen Schritt erkannt wurde, und gleicht diese mit den erhaltenen Bewerbungsunterlagen ab. Anschließend schreibt die KI eine erste Einschätzung als
            Kommentar
            zu der Bewerbung.</span>
          <input type="checkbox" @change="saveKISettings()" id="ki_evaluation" v-model="kiEvaluation"><label for="ki_evaluation">Aktivieren</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "../../API/Business"
export default {
  props: {
    user: Object,
  },
  components: {},
  data() {
    return {
      mailbox: {
        mailboxType: "",
        mailboxserver: "",
        mailboxport: "",
        mailboxusername: "",
        mailboxpassword: "",
        mailboxEnabled: false,
        mailboxIgnoreBefore: "",
        mailboxForwarder: "",
      },
      mailboxForwarderSaved: "",
      currentTenantData: {},
      resultMessage: "",
      kiEvaluation: false,
      loading: false,
      dataChanged: false,
    };
  },
  methods: {
    testMailboxConnection() {
      this.loading = "testConnection"
      API.testMailboxConnection(this.mailbox, (result) => {
        if (result) {
          this.resultMessage = result.status
          this.loading = false;
        }
      })
    },
    setMailboxType() {
      API.updateTenantById(this.user.currentTenant, { mailboxType: this.mailbox.mailboxType }, (result) => {

      })
    },
    saveKISettings() {
      API.updateTenant({ id: this.user.currentTenant, kiEvaluation: this.kiEvaluation }, (result) => {

      })
    },
    saveMailbox() {
      this.mailbox.id = this.user.currentTenant
      API.updateTenant(this.mailbox, (result) => {
        if (result) {
          this.dataChanged = false
          this.loading = false;
          this.resultMessage = result.status
          this.mailboxForwarderSaved=this.mailbox.mailboxForwarder
        }
      })
    },
    setMailboxstatus(status) {
      API.updateTenant({ id: this.user.currentTenant, mailboxEnabled: status }, (result) => {
        if (result) {
          this.mailbox.mailboxEnabled = status
        }
      })
    }
  },
  created: function () {
    if (this.user.currentTenantData) {
      this.mailbox.mailboxserver = this.user.currentTenantData.mailboxserver
      this.mailbox.mailboxport = this.user.currentTenantData.mailboxport
      this.mailbox.mailboxusername = this.user.currentTenantData.mailboxusername
      this.mailbox.mailboxpassword = this.user.currentTenantData.mailboxpassword
      this.mailbox.mailboxEnabled = this.user.currentTenantData.mailboxEnabled
      this.mailbox.mailboxIgnoreBefore = this.user.currentTenantData.mailboxIgnoreBefore
      this.mailbox.mailboxType = this.user.currentTenantData.mailboxType
      this.mailbox.mailboxForwarder = this.user.currentTenantData.mailboxForwarder
      this.mailboxForwarderSaved = this.user.currentTenantData.mailboxForwarder
      if (this.mailbox.mailboxIgnoreBefore && this.mailbox.mailboxIgnoreBefore.includes("T")) {
        this.mailbox.mailboxIgnoreBefore = this.mailbox.mailboxIgnoreBefore.split("T")[0]
      }
      this.kiEvaluation = this.user.currentTenantData.kiEvaluation
    }
  },
};
</script>

<style scoped></style>
