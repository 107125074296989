<template>
  <div class="
      w-56
      pt-20
      px-2
      bg-gradient-to-t
      from-secondary
      to-secondary
      h-full
      fixed
      top-0
      z-10
      text-left text-primary
    ">
    <a v-if="lastCompany" :href="'/p/' + lastCompany">
      <div v-bind:class="[
      $route.path == '/p/' + lastCompany ? 'bg-primary text-secondary' : 'text-primary',
    ]" class="mt-8 rounded-lg cursor-pointer p-3 flex flex-row items-center relative">
        <font-awesome-icon size="2x" class="w-7 cursor-pointer inline" icon="building" /><span class=" text-inherit font-bold text-xl align-top ml-2">{{ lastCompanyName }}</span>
      </div>
    </a>
    <a @click="$emit('hideNav')" href="/c/overview">
      <div v-bind:class="[
      $route.path == '/c/overview' ? 'bg-primary text-secondary' : 'text-primary',
    ]" class="mt-8 rounded-lg cursor-pointer p-3 inline-block relative">
        <font-awesome-icon size="2x" class="w-7 inline align-middle" icon="chart-simple" />
        <span class="font-extrabold text-inherit text-xl align-top ml-2">Bewerbungen</span>
      </div>
    </a>
    <a @click="$emit('hideNav')" href="/c/docs">
      <div v-bind:class="[
      $route.path == '/c/docs' ? 'bg-primary text-secondary' : 'text-primary',
    ]" class="mt-8 rounded-lg cursor-pointer p-3 inline-block relative">
        <font-awesome-icon size="2x" class="w-7 cursor-pointer inline" icon="file-invoice" /><span class=" text-inherit font-bold text-xl align-top ml-2">Dokumente</span>
      </div>
    </a>
    <div class="absolute left-0 bottom-0 pb-4 w-full text-center">
      <button class="text-primary border-primary" @click="logout()">Ausloggen</button>
    </div>
  </div>

</template>
<script>
import Auth from '@/API/Auth';
export default {
  name: "TenantTile",
  computed: {},
  emits: ["hideNav"],
  props: {
    user: Object
  },
  data() {
    return {
      lastCompany: "",
      lastCompanyName: "",
    };
  },
  methods: {
    logout() {
      Auth.logout(res => {
        window.location = "/login";
      })
    },
    getCookie(name) {
      name = name + '='
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');

      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return ""
    },
  },
  created: function () {
    this.lastCompany = this.getCookie("lastCompany");
    this.lastCompanyName = this.getCookie("lastCompanyName");
  }
};
</script>