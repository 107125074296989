<template>
  <div class="p-3 inline-block">
    <div
      id="tile"
      v-bind:class="[
        application == currentApplication ? 'shadow-selected' : 'shadow-lg',
        application.status == 'Abgesagt' ? 'bg-red-100' : 'bg-white',
      ]"
      class="cursor-pointer p-4 inline-block max-w-96 rounded-xl relative whitespace-normal shadow-lg"
    >
      <div>
        <span class="text-xl font-medium leading-none">{{
          application.companyName
        }}</span>
        <div v-if="application.jobtitle">
          <span class="text-sm leading-none">{{ application.jobtitle }}</span>
        </div>

        <div class="hidden ml-2 rounded-full bg-primary/25 py-0.5 px-3">
          <span class="text-primary">New</span>
        </div>
        <div
          v-if="application.details"
          class="iconborder"
          @click="$emit('openJobDetails')"
        >
          <font-awesome-icon icon="ellipsis" class="inborder" />
        </div>
      </div>
      <div
        v-if="application.contact_name"
        class="mt-4 px-1 inline-block min-w-1/2"
      >
        <font-awesome-icon icon="user" size="lg" />
        <span class="ml-5 align-middle">{{ application.contact_name }}</span>
      </div>
      <div
        v-if="application.contact_phone"
        class="mt-4 px-1 inline-block min-w-1/2"
      >
        <font-awesome-icon class="text-black" icon="phone" size="lg" />
        <a @click.stop="" target="_blank" :href="'tel:'+application.contact_phone" class="ml-5 align-middle">{{ application.contact_phone }}</a>
      </div>
      <div
        v-if="application.contact_mail"
        class="mt-4 px-1 inline-block min-w-1/2"
      >
        <font-awesome-icon class="text-black" icon="envelope" size="lg" />
        <a @click.stop="" target="_blank" :href="'mailto:'+application.contact_mail" class="ml-5 align-middle">{{ application.contact_mail }}</a>
      </div>

      <div class="mt-2 px-0.5 inline-block pr-5">
        <font-awesome-icon class="text-secondary" size="lg" icon="message" />
        <span class="ml-4 align-middle">{{ application.comments }}</span>
      </div>
      
      <div
        v-if="application.website && application.website.startsWith('http')"
        class="mt-2 inline-block min-w-1/2"
      >
        <font-awesome-icon class="ml-1" icon="earth-americas" />
        <a
          target="_blank"
          v-if="this.application.website.startsWith('http')"
          :href="application.website"
          class="underline ml-4 align-middle"
          >Website</a
        >
      </div>
      <div
        v-if="application.weblink && application.weblink.startsWith('http')"
        class="mt-2 inline-block min-w-1/2"
      >
        <font-awesome-icon class="ml-1" icon="earth-americas" />
        <a
          target="_blank"
          v-if="this.application.weblink.startsWith('http')"
          :href="application.weblink"
          class="underline ml-4 align-middle"
          >{{ portal }}</a
        >
        <span v-else class="ml-3">{{ portal }}</span>
      </div>
      <div
        v-if="application.scheduled && application.status == 'Interview'"
        class="ml-1 mt-2 px-0.5 inline-block min-w-1/2"
      >
        <font-awesome-icon
          class="text-green-500"
          size="lg"
          icon="calendar-check"
        />
        <span class="ml-4 align-middle text-green-500">{{ scheduled }}</span>
      </div>
      <span class="mt-2 block text-sm leading-none text-right"
          >Erstellt: {{ application.created_at }}</span
        ><span v-if="application.updated_at != application.created_at" class="mt-2 block text-sm leading-none text-right"
          >Aktualisert: {{ application.updated_at }}</span
        >
    </div>
  </div>
</template>
<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      showFields: [
        "showDate",
        "weekhours",
        "applications",
        "portal",
        "details",
      ],
    };
  },
  props: {
    application: Object,
    currentApplication: Object,
  },
  computed: {
    startDate: function () {
      let es = Date.parse(this.application.startDate + "T00:00");
      return isNaN(es)
        ? "Sofort"
        : new Date(es).toLocaleString("de-DE").split(",")[0];
    },
    created: function () {
      let es = Date.parse(this.application.created_at + "T00:00");
      return isNaN(es)
        ? "Sofort"
        : new Date(es).toLocaleString("de-DE").split(",")[0];
    },
    scheduled: function () {
      let es = Date.parse(this.application.scheduled.replace("Z",""));
      return isNaN(es)
        ? "Kein Termin vereinbart"
        : new Date(es).toLocaleString("de-DE").slice(0, -3);
    },
    portal: function () {
      if (this.application.weblink.startsWith("http")) {
        let link = this.application.weblink;
        if (link.match(/([a-z-]*)\.[a-z]*\//)) {
          let portal = link.match(/([a-z-]*)\.[a-z]*\//)[1];
          return portal.charAt(0).toUpperCase() + portal.slice(1);
        } else if (link.match(/([a-z-]*)\.[a-z]*/)) {
          let portal = link.match(/([a-z-]*)\.[a-z]*/)[1];
          return portal.charAt(0).toUpperCase() + portal.slice(1);
        }
      }
      return "-";
    },
  },
  created: function () {
    if (this.fields) {
      this.showFields = this.fields;
    }
  },
};
</script>