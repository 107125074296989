<template>
  <div>
  <NavBar v-if="user" :user="user" :mode="user.type" @navigate="navigate" class="sm:block" :class="{'hidden': !showNav, '!w-full': showNav} " />
  <SearchBar ref="searchbar" v-if="user" :user="user" @changedTenant="changedTenant" @toggleNav="showNav = !showNav" />
  <div class="
      sm:pl-56
      w-full
      absolute
      top-0
      pt-20
      bottom-0
      text-text-color
      box-border
      bg-gray-100
    " tabindex="0" v-on:keyup.esc="escPressed()">
    <BoardView v-if="user && $route.path == '/b/board'" :user="user" ref="board"/>
    <ArchiveView v-if="user && $route.path == '/b/archive'" :user="user" />
    <OrganizationView v-if="user && $route.path == '/b/org'" :user="user" @tenantCreated="getBusinessUser()" @refreshUser="getBusinessUser()" />
    <PersonalSettingsView v-if="user && $route.path == '/b/settings'" :user="user" @tenantCreated="getBusinessUser()" @refreshUser="getBusinessUser()" />
    <TemplateView v-if="user && $route.path == '/b/templates'" :user="user" />
  </div>
</div>
</template>
<script>
  // @ is an alias to /src
  import SearchBar from "@/components/SearchBar.vue";
  import BoardView from "@/views/business/BoardView.vue";
  import ArchiveView from "@/views/business/ArchiveView.vue";
  import TemplateView from "@/views/business/TemplateView.vue";
  import OrganizationView from "@/views/business/OrganizationView.vue";
  import PersonalSettingsView from "@/views/business/PersonalSettingsView.vue";
  import NavBar from "@/components/NavBar.vue";
  import { useCookies } from "vue3-cookies";
  import BusinessAPI from "@/API/Business";
  export default {
    setup() {
      const { cookies } = useCookies();
      return { cookies };
    },
    name: "BusinessMainView",
    components: {
      TemplateView,
      BoardView,
      ArchiveView,
      PersonalSettingsView,
      OrganizationView,
      SearchBar,
      NavBar,
    },
    data() {
      return {
        user: false,
        showNav: false,
      };
    },
    methods: {
      navigate(path) {
        history.pushState({}, null, path);
        this.$router.push(path);
      },
      getBusinessUser() {
        let vm = this;
        BusinessAPI.getUser((user) => {
          if (user) {
            if (user.currentTenant) {
              user.currentTenantData = user.tenants.find((tenant) => {
                return tenant.id == user.currentTenant;
              });
            }
            vm.user = user;
            console.log(user);
            if (vm.path == "/b/") {
              window.location = "/b/board";
            }
          } else {
            vm.cookies.set("redirect", vm.$route.fullPath.trim());
            window.location = "/login";
          }
        });
      },
      changedTenant(newCurrentTenant) {
        let vm=this;
        if (newCurrentTenant == "addNewTenant") {
          this.user.currentTenantData = {};
          this.navigate("/b/org");
        } else {
          this.user.currentTenantData = this.user.tenants.find((tenant) => {
            return tenant.id == newCurrentTenant;
          });
        }
        BusinessAPI.updateUser({ currentTenant: newCurrentTenant }, () => { 
          vm.$refs.board.getJobs(true);
        });
      },

      escPressed() {
        this.detailsVisible = false;
        this.showEditJob = false;
        this.showApplicationDetails = false;
      },
    },
    created: function () {
      this.getBusinessUser();
    },
  };
</script>
<style scoped>
</style>