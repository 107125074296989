<template>
  <div class="bg-white w-full h-full text-center">
    <div class="w-full inline-block p-4 relative">
      <div class="absolute top-5 right-5 text-center">
        <vue-qrious :value="publicLink" :size=400 :padding=20 class="w-32 inline-block" /><br>
        <span class="w-40 inline-block text-sm " >Zum Bewerben QR-Code scannen oder auf <span class="underline font-bold">{{domain}}<br>/p/{{tenant.slug}}</span><br>gehen.</span><br>
        <img
          class="p-2 text-primary w-32 md:w-44 hidden"
          src="@/static/LogoColored.png"
         /><br />
        </div>
      <span class="w-full inline-block text-left">Wir suchen:</span><br />
      <br />
      <span class="font-bold text-5xl px-44 text-center inline-block">{{ job.title }}</span><br>
      <div class="text-left w-5/6 inline-block mt-10" v-if="job">
        <div v-if="job.targetDate" class="grid grid-cols-2">
          <span class="block font-semibold text-lg">Verfügbar ab:</span>
          <span class="block font-semibold text-lg ">{{ job.targetDate }}</span>
        </div>
        <div class="grid grid-cols-2">
          <span class="block font-semibold text-lg">Unternehmen:</span>
          <span class="block font-semibold text-lg ">{{ tenant.name }}</span>
        </div>
        <div v-if="job.weekhours" class="grid grid-cols-2">
          <span class="block font-semibold text-lg">Wochenstunden:</span>
          <span class="block font-semibold text-lg ">{{ job.weekhours }}</span>
        </div>
        <div v-if="job.location" class="grid grid-cols-2">
          <span class="block font-semibold text-lg">Ort:</span>
          <span class="block font-semibold text-lg ">{{ job.location }}</span>
        </div>
        <div v-if="job.department" class="grid grid-cols-2">
          <span class="block font-semibold text-lg">Abteilung:</span>
          <span class="block font-semibold text-lg ">{{ job.department }}</span>
        </div>
        <div class="grid grid-cols-2">
          <span class="block font-semibold text-lg ">Homeoffice möglich:</span>
          <span v-if="job.homeoffice" class="block font-semibold text-lg "
            >Ja</span
          >
          <span v-else class="block font-semibold text-lg">Nein</span>
        </div>
<div>                <div class="mt-2  relative" v-if="job.tasks">
                  <span class="font-bold text-xl">Aufgaben:</span><br />
                  <pre class="font-normal text-black whitespace-pre-line break-normal">{{ job.tasks }}</pre>
                </div>
                <div class="mt-2" v-if="job.profile">
                  <span class="font-bold text-xl">Profil:</span><br />
                  <pre class="font-normal text-black whitespace-pre-line break-normal">{{ job.profile }}</pre>
                </div>
                <div class="mt-2" v-if="job.benefits">
                  <span class="font-bold text-xl">Das bieten wir:</span><br />
                  <pre class="font-normal text-black whitespace-pre-line break-normal">{{ job.benefits }}</pre>
                </div>
              </div>
              <div class="mt-2" v-if="tenant.aboutus">
                  <span class="font-bold text-xl">Über uns:</span><br />
                  <pre class="font-normal text-black whitespace-pre-line break-normal">{{ tenant.aboutus }}</pre>
                </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { useCookies } from "vue3-cookies";
import API from "@/API/Business";
import VueQrious from "vue-qrious"
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  name: "FlyerView",
  components: {VueQrious},
  props: {},
  data() {
    return {
      job: {},
      tenant: {},
      domain: "",
    };
  },

  methods: {
    init() {
      let vm = this;
      API.getJob(
        this.$route.query.j,
        (job) => {
          vm.job = job;
          console.log(job);
        }
      );
      API.getTenant(
        (tenant) => {
          vm.tenant = tenant;
        }
      );
    },
  },
  watch: {},
  computed: {
    publicLink: function () {
      return process.env.VUE_APP_FE_DOMAIN+"/"+this.tenant.slug+"?j=" + this.job.id;
    },
  },
  created: function () {
    this.domain=process.env.VUE_APP_FE_DOMAIN
    this.init();
  },
};
</script>
<style scoped>
.sortable-ghost {
  opacity: 0.3;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 4s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
</style>