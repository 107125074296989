<template>
  <div>
    <Transition>
      <TopBanner mode="error" :text="bannerText" v-if="bannerText" />
    </Transition>
    <AddFileModal
      v-if="showAddFileModal"
      kind="Dokument"
      @closeModal="showAddFileModal = false"
      @addFile="addFile"
    />
    <div
      class="h-full p-5 w-full  border-l-2 border-secondary bg-white overflow-y-auto relative"
      id="detailsSidebar"
    >
      <div
        class="w-full text-center h-full absolute top-96 left-0 z-40"
        v-if="displayInterviewPopup"
      >
        <div
          class="bg-white rounded-lg shadow-lg border-2 border-black w-5/6 inline-block mt-50 p-2 relative"
        >
          <div @click="displayInterviewPopup = false" class="iconborder">
            <font-awesome-icon icon="xmark" class="inborder" />
          </div>
          <span class="font-bold block"> Wann findet das Interview statt?</span>
          <input
            class="mt-4 inline-block font-semibold text-left"
            type="datetime-local"
            v-model="interviewDate"
          /><br />
          <button @click="setupInterview()" class="mt-2">Bestätigen</button>
        </div>
      </div>
      <div @click="$emit('closeDetails')" class="iconborder">
        <font-awesome-icon icon="xmark" class="inborder" />
      </div>
      <div class="text-center w-full">
        <div class="mt-8">
          <input
            class="text-center text-2xl font-bold w-full"
            placeholder="Max Mustermann"
            v-model="editableApplication.name"
            @change="updateApplication('name')"
          />
        </div>
        <button class="mt-2 border-green-500 text-green-500" @click="$emit('showPopup')">Ausklappen</button>
        <div v-if="!editableApplication.encrypted">
          <span class="font-bold text-red-500"
            >Diese Bewerbung ist noch nicht Client-verschlüsselt, da sie
            importiert wurde.</span
          >
          <button
            class="border-red-500 text-red-500 font-bold mt-3"
            @click="encryptApplication"
          >
            Jetzt verschlüsseln
          </button>
        </div>
        <div class="pl-4">
          <div class="border-b border-b-black w-full my-4"></div>
        </div>

        <div class="w-full flex flex-row">
          <div class="inline-block grow text-center">
            <button
              v-if="mode != 'archive'"
              @click="$emit('sentCancelMail')"
              class="bad mx-2"
            >
              Löschen
            </button>
            <button
              @click="$emit('assignApplication')"
              class="negative mx-2 mt-2"
              title="Diesen Bewerber einem Job zuweisen"
            >
              Zu Stelle verschieben
            </button>
          </div>
        </div>
        <div class="pl-4">
          <div class="border-b border-b-black w-full my-4"></div>
        </div>
        <div class="mt-7">
          <div class="w-full text-left">
            <span class="text-xl">Kommentare</span>
            <div class="iconborder" @click="showAddComment = true">
              <font-awesome-icon icon="plus" class="inborder" />
            </div>
          </div>
          <div v-if="showAddComment" class="relative p-0 pt-3">
            <textarea
              v-model="newComment"
              class="border p-3 w-full h-20 bg-gray-100 resize-none"
            ></textarea>
            <div class="w-full text-right">
              <button class="py-0 px-1 rounded-2xl" @click="addComment()">
                Hinzufügen
              </button>
            </div>
          </div>
          <div>
            <div
              class="w-full text-left border-b mb-2"
              v-for="comment in comments"
              :key="comment.id"
            >
              <div>
                <span class="text-xs font-bold text-primary">{{
                      comment.author
                    }}</span
                    ><font-awesome-icon
                      icon="thumbtack"
                      class="float-right mt-1 cursor-pointer"
                      :class="{ 'text-primary': comment.pinned }"
                      @click="pinComment(comment)"
                    /><span class="float-right mr-2 text-primary text-xs font-bold">{{
                      comment.time
                    }}</span>
              </div>
              <pre class="w-full whitespace-pre-wrap overflow-y-auto">{{
                comment.message
              }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/API/Business";
import { useCookies } from "vue3-cookies";
import AddFileModal from "./AddFileModal.vue";
import TopBanner from "./TopBanner.vue";
import Encryption from "@/Encryption";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  components: {
    AddFileModal,
    TopBanner,
  },
  expose: ["loadComments"],
  props: {
    application: Object,
    cryptKey: String,
    currentJob: Object,
    mode: String,
    hr: Boolean,
  },
  emits: [
    "closeDetails",
    "fileReceived",
    "applicationUpdated",
    "sentCancelMail",
    "deleteOrArchiveApplication",
    "assignApplication",
    "deleteApplication",
    "banner",
    "showPopup"
  ],
  data() {
    return {
      plannedInterview: "",
      interviewDate: "",
      editableApplication: {},
      editName: false,
      comments: [],
      loadingFile: false,
      newComment: "",
      showAddComment: false,
      cancelToolTip: "",
      interviews: 1,
      displayInterviewPopup: false,
      showAddFileModal: false,
      bannerText: "",
      subjects: {
        cancel: "Ihre Bewerbung bei uns",
        interview: "Ihre Bewerbung bei uns",
      },
    };
  },
  methods: {
    planInterview(type) {
      this.plannedInterview = "scheduled" + type;
      this.interviewDate = this.editableApplication[this.plannedInterview];
      this.displayInterviewPopup = true;
    },
    setupInterview() {
      this.displayInterviewPopup = false;
      console.log(this.interviewDate);
      this.editableApplication[this.plannedInterview] = this.interviewDate;
      console.log(this.editableApplication[this.plannedInterview]);
      this.editableApplication.status = "Interview";
      setTimeout(() => {
        this.updateApplication(this.plannedInterview);
      }, 500);
      this.updateApplication("status");
      this.sendMail("interview");
    },
    sendMail(type) {
      let vm = this;
      if (this.editableApplication.status == "Abgesagt") {
        if (type == "cancel") {
          vm.$emit("sentCancelMail");
        }
        return;
      }
      API.getTemplates((templates) => {
        let subjects = {
          cancel: "Deine Bewerbung bei uns",
          interview: "Einladung zum Vorstellungsgespräch",
        };
        let text = templates[type];
        let es = Date.parse(this.interviewDate);
        if (!isNaN(es)) {
          let d = new Date(es).toLocaleString("de-DE");
          text = text.replace("{Interviewdatum}", d.split(",")[0]);
          text = text.replace(
            "{Interviewuhrzeit}",
            d.split(",")[1].substr(0, d.split(",")[1].length - 3)
          );
        }
        text = text.replace("{Name}", vm.editableApplication.name);
        console.log(vm.application.job);
        text = text.replace("{Jobtitel}", vm.currentJob.title);
        //text = text.replaceAll("\n", "%0D%0A");
        text = encodeURIComponent(text);
        console.log(
          "mailto:" +
            vm.editableApplication.mail +
            "?subject=" +
            vm.subjects[type] +
            "&body=" +
            text,
          "_blank"
        );
        window.open(
          "mailto:" +
            vm.editableApplication.mail +
            "?subject=" +
            vm.subjects[type] +
            "&body=" +
            text,
          "_blank"
        );
        if (type == "cancel") {
          vm.$emit("sentCancelMail");
        }
      });
    },
    getFile(file) {
      let vm = this;
      vm.loadingFile = true;
      API.getFile(file.id, this.cryptKey, (receivedFile) => {
        vm.loadingFile = false;
        if (receivedFile) {
          if (navigator.pdfViewerEnabled) {
            vm.$emit("fileReceived", receivedFile);
            vm.loadingFile = false;
          } else {
            let ct = "text/plain";
            let contenttypes = {
              ovpn: "text/plain",
              pdf: "application/pdf",
              jpg: "image/jpeg",
              jpeg: "image/jpeg",
              png: "image/png",
            };
            let fileType = "unknown";
            if (receivedFile.originalName) {
              fileType = receivedFile.originalName.split(".").slice(-1)[0];
            }
            if (Object.keys(contenttypes).includes(fileType)) {
              ct = contenttypes[fileType];
            }
            const link = document.createElement("a");
            // create a blobURI pointing to our Blob
            link.href = window.URL.createObjectURL(
              Encryption.b64toBlob(receivedFile.data, ct)
            );
            link.download = receivedFile.name;
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            console.log(link);
            link.click();
            link.remove();
          }
        }
      });
    },
    loadComments() {
      let vm = this;
      API.getCommentsForApplication(
        this.editableApplication,
        this.cryptKey,
        (comments) => {
          vm.comments = comments;
          vm.newComment = "";
          vm.editableApplication.comments = comments.length;
        }
      );
    },
    addFile(file) {
      this.editableApplication.files.push(file);
      this.showAddFileModal = false;
      this.uploadApplicationFile(file);
    },
    deleteFile(file) {
      let vm = this;
      API.deleteFile(file, (s) => {
        if (s) {
          vm.$emit("applicationUpdated", "files");
        }
      });
    },
    uploadApplicationFile(file) {
      let vm = this;
      file.application = this.editableApplication.id;
      API.addFile(file, this.cryptKey, (s) => {
        if (s) {
          file = s;
          vm.$emit("applicationUpdated", "files");
        }
      });
    },
    addComment() {
      let vm = this;
      let comment = {
        message: this.newComment,
        application: this.application.id,
      };
      API.addComment(comment, this.cryptKey, (s) => {
        if (s) {
          vm.loadComments();
          vm.showAddComment = false;
        }
      });
    },
    pinComment(comment) {
      console.log("hello");
      API.pinComment(comment, (s) => {});
      comment.pinned = !comment.pinned;
      if (comment.pinned) {
        this.editableApplication.pinnedComments.push(comment);
      } else {
        this.editableApplication.pinnedComments.splice(
          this.editableApplication.pinnedComments.indexOf(comment),
          1
        );
      }
    },
    encryptApplication() {
      let vm = this;
      API.updateApplication(
        this.editableApplication,
        this.cryptKey,
        this.editableApplication.id,
        () => {
          vm.$emit("applicationUpdated", "status");
        }
      );
    },
    updateApplication(field) {
      let update = {};
      update[field] = this.editableApplication[field];
      let vm = this;
      API.updateApplication(
        update,
        this.cryptKey,
        this.editableApplication.id,
        (success) => {
          if (success) {
            if (field == "status") {
              vm.$emit("applicationUpdated", "status");
            } else {
              vm.$emit("applicationUpdated");
            }
          }
        }
      );
    },
  },
  created: function () {
    this.editableApplication = this.application;
    if (this.application.scheduled2) this.interviews++;
    if (this.application.scheduled3) this.interviews++;
    this.loadComments();
    setTimeout(() => {
      if (!this.hr) {
        let els = Array.from(
          document
            .getElementById("detailsSidebar")
            .getElementsByTagName("input")
        ).concat(
          Array.from(
            document
              .getElementById("detailsSidebar")
              .getElementsByTagName("select")
          )
        );
        for (let i = 0; i < els.length; i++) {
          els[i].disabled = true;
        }
      }
    }, 500);
  },
  watch: {
    application(newApplication, oldApplication) {
      this.editableApplication = newApplication;
      if (newApplication) {
        this.loadComments();
        this.showAddComment = false;
      }
    },
  },
  computed: {
    initials: function () {
      let nameparts = this.application.name.trim().split(" ");
      if (nameparts == "") {
        return "N/A";
      }
      if (nameparts.length == 1) {
        return nameparts[0][0];
      }
      return nameparts[0][0] + nameparts[nameparts.length - 1][0];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.detailscontent {
  width: 100%;
  height: 100%;
  border-left: 4px solid #a3bbc7;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

option,
option:checked {
  background: #292f4c;
}

input,
select {
  padding: unset;
  border: unset;
  background: unset;
  margin: unset;
}

select {
  width: unset;
}
</style>