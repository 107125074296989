<template>
  <div class="w-full h-20 fixed top-0 bg-white z-40" style="box-shadow: 0px 2px 10px lightgray">
    <div class="text-center  hidden lg:inline-block">
      <img class="px-2 w-20 align-middle -mt-5 text-primary inline-block" src="@/static/LogoOrange.png" />
      <span class="font-extrabold mt-3 text-5xl text-secondary">Apply</span>
      <span class="font-extrabold mt-3 text-5xl text-primary">hr</span>
    </div>
    <div class="inline-block align-top mt-4 w-2/3 sm:w-1/3">
      <div class="border-2 border-solid border-gray-300 rounded-full ml-4 p-2 box-border w-full flex flex-row">
        <font-awesome-icon class="px-2 text-gray-400" icon="search" />
        <input @focus="showEntries = true" @blur="closeSearch()" v-model="filter" class="grow border-0 focus:no-underline no-underline" placeholder="Suche" />
      </div>
      <div v-if="showEntries" class="ml-5 p-2 text-center bg-white rounded-lg shadow-lg max-w-lg max-h-96 overflow-auto">
        <div v-for="entry in filteredEntries" :key="entry" class="w-full border-b border-b-primary last:border-b-0 text-left p-3 hover:bg-primary/10 cursor-pointer" @click="clickEntry(entry)">
          <div v-if="entry.type == 'job'" class="flex flex-row">
            <span class="text-xl font-bold flex-grow">{{ entry.title }}</span>
            <div class="ml-2 inline-block">
              <span class="text-primary rounded-full bg-primary/25 py-0.5 px-3  inline-block">Stellenanzeige</span>
            </div>
          </div>
          <div v-if="entry.type == 'application'">
            <span class="text-xl font-bold">{{ entry.name }} ({{ entry.jobtitle }})</span><br />
            <div class="inline-block">
              <span class="">{{ entry.mail }}</span><br />
              <span class="">{{ entry.phone }}</span>
              <div v-if="entry.archived">
                <div class="rounded-full bg-red-500/25 py-0.5 px-3 mt-2 inline-block">
                  <span class="text-red-500">Archiviert</span>
                </div>
              </div>
            </div>
            <div class="ml-2 rounded-full bg-primary/25 py-0.5 px-3 mt-2 inline-block float-right">
              <span class="text-primary">Bewerber</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="float-right mt-4 mr-4">
      <div class="hidden sm:inline-block align-middle mr-4">
        <div class="inline-block">
          <span class="ml-1 text-secondary">{{ editableUser.mail }}</span><br />
          <span v-if="!editableUser.currentTenant" class="font-bold text-primary border-b-primary border-b-2 cursor-pointer" onclick="window.location='/b/org?new'">Unternehmen anlegen</span>
          <div v-else>
            <select class="bg-white text-primary" v-model="editableUser.currentTenant" @change="changeDefault()">
              <option v-for="tenant in editableUser.tenants" :key="tenant.id" :value="tenant.id">
                {{ tenant.name }}
              </option>
              <option v-if="!editableUser.hasCreatedTenant" class="text-secondary" value="addNewTenant">
                Neues Unternehmen anlegen
              </option>
            </select>
          </div>

        </div>
        <font-awesome-icon @click="showlogout = !showlogout" size="2x" class="px-2 cursor-pointer text-secondary align-top" icon="user" />
      </div>
      <div class="inline-block sm:hidden" @click="$emit('toggleNav')">
        <font-awesome-icon size="2x" class="px-2 cursor-pointer text-secondary align-top" icon="bars" />
      </div>
      <div v-if="showlogout" class="z-50 bg-white absolute top-14 right-0 p-5 whitespace-nowrap text-left">
        <button @click="logout()" class="bg-white my-2">Ausloggen</button>
      </div>
    </div>
  </div>
</template>
<script>
import { useCookies } from "vue3-cookies";
import Auth from "@/API/Auth";
import API from "@/API/Business";
import Encryption from "@/Encryption";
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
    user: Object,
  },
  data() {
    return {
      showlogout: false,
      editableUser: this.user,
      encryptionKey: "",
      searchEntries: [],
      showEntries: false,
      filter: "",
    };
  },
  methods: {
    closeSearch() {
      setTimeout(() => (this.showEntries = false), 200);
    },
    clickEntry(entry) {
      if (entry.type == "application") {
        if (entry.archived) window.location = "/b/archive?a=" + entry.id;
        else window.location = "/b/board?j=" + entry.job_id + "&a=" + entry.id;
      } else if (entry.type == "job") {
        window.location = "/b/board?j=" + entry.id;
      }
    },
    switchSpace() {
      Auth.switchSpace("private", () => {
        window.location = "/";
      });
    },
    logout() {
      Auth.logout(res => {
        window.location = "/login";
      })
    },
    changeDefault() {
      this.$emit("changedTenant", this.editableUser.currentTenant);
    },
  },
  computed: {
    filteredEntries: function () {
      let vm = this;
      return this.searchEntries.filter((entry) => {
        let fp = vm.filter.split(" ");
        for (let i = 0; i < fp.length; i++) {
          if (!(entry.strings && entry.strings.includes(fp[i].toLowerCase()))) {
            return false;
          }
        }
        return true;
      });
    },
  },
  created: function () {
    this.encryptionKey = localStorage.getItem(
      "encryptionKey" + this.user.currentTenant
    );
    let vm = this;
    API.getSearchEntries((entries) => {
      console.log(entries);
      entries = Array.from(entries);
      for (let i = 0; i < entries.length; i++) {
        entries[i] = Encryption.decryptApplication(entries[i], vm.encryptionKey);
      }

      entries
        .filter((entry) => entry.type == "application")
        .forEach((entry) => {
          entry.strings = "";
          entry.strings += entry.name;
          entry.strings += entry.jobtitle;
          entry.strings += entry.phone;
          entry.strings += entry.mail;
          entry.strings = entry.strings.toLowerCase();
        });
      console.log(entries);
      vm.searchEntries = entries;
    });
  },
};
</script>
<style scoped>
input,
select {
  padding: unset;
  margin: unset;
  background: unset;
  width: unset;
  border: unset;
}
</style>
