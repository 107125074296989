import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/LoginView.vue";
import BusinessMainView from "../views/BusinessMainView.vue";
import FlyerView from "../views/business/FlyerView.vue";
import QRView from "../views/business/QRView.vue";
import PrivateMainView from "../views/PrivateMainView.vue";
import TenantJobsFrame from "../views/private/TenantJobsFrame.vue";

const routes = [
    {
        path: '/iframes/:slug(.*)',
        name: "TenantJobsFrame",
        component: TenantJobsFrame,
    },
    {
        path: '/p/:slug(.*)/:jobId(.*)',
        name: "PrivateMainJob",
        component: PrivateMainView,
    },
    {
        path: '/p/:slug(.*)',
        name: "PrivateMainOverview",
        component: PrivateMainView,
    },
    {
        path: '/c/overview',
        name: "Dashboard",
        component: PrivateMainView,
    },
    {
        path: '/p/',
        name: "TenantOverview",
        component: PrivateMainView,
    },
    
    {
        path: '/c/docs',
        name: "Documents",
        component: PrivateMainView,
    },
    {
        path: '/c/:catchAll(.*)',
        name: "PrivateMainUser",
        component: PrivateMainView,
    },
    
    {
        path: '/b/flyer',
        name: "FlyerView",
        component: FlyerView,
    },
    {
        path: '/b/qr',
        name: "QRView",
        component: QRView,
    },
    {
        path: '/b/:catchAll(.*)',
        name: "BusinessMain",
        component: BusinessMainView,
    },
    {
        path: '/login',
        name: "CatchAll",
        component: LoginView,
    },
    {
        path: '/:catchAll(.*)',
        name: "CatchAll",
        component: LoginView,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;