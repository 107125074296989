<template>
  <div class="w-full h-full overflow-y-auto">
    <div class="w-full xl:-translate-y-1/2 xl:absolute xl:top-1/2 text-center">
      <div>
        <img
          class="p-2 text-primary w-32 md:w-44 inline-block"
          src="@/static/LogoColored.png"
        /><br />
        <span class="font-extrabold text-3xl md:text-6xl text-secondary"
          >Apply</span
        >
        <span class="font-extrabold text-3xl md:text-6xl text-primary">hr</span>
      </div>
      <LoginComponent 
        v-if="mode != 'private'"
        type="business"
        class="lg:ml-10"
      /><LoginComponent v-if="mode != 'business'" type="private" class="lg:mr-10"/>
      
      <div>
        <span class="inline-block font-bold mt-3"
          >Mit Durchführung des Logins akzeptieren Sie die
          <a
            class="underline"
            target="_blank"
            href="https://www.applyhr.de/datenschutz/"
            >Datenschutz-</a
          >
          und
          <a
            class="underline"
            target="_blank"
            href="https://www.applyhr.de/nutzungsbedingungen"
            >Nutzungsbedingungen</a
          ></span
        ><br><span class=""
          >Wir werden Ihnen für private Accounts außer der Login-Mail
          <span class="underline ">nie</span> E-Mails
          schicken und die Accounterstellung ist und bleibt kostenlos.</span
        >
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useCookies } from "vue3-cookies";
import Auth from "@/API/Auth";
import LoginComponent from "@/components/LoginComponent.vue";
import AppleHintNotification from '@/components/notifications/AppleHintNotification.vue';
export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      usedCode: false,
      mode: "all",
    };
  },
  name: "LoginView",
  components: {
    LoginComponent,
  },
  methods: {
    checkAuth() {
      let vm = this;
      Auth.checkAuth((success) => {
        if (success) {
          let redirect = vm.cookies.get("redirect");
          if (redirect) {
            vm.cookies.remove("redirect");
            document.location = redirect;
          } else {
            if (success.type == "private") document.location = "/c/overview";
            else document.location = "/b/board";
          }
        }
      });
    },
  },
  created: function () {
  let contextjson=localStorage.getItem(this.$route.query.state)
     if (this.$route.query.mode) this.mode = this.$route.query.mode;
    if (this.$route.query.code && this.$route.query.state && contextjson) {
      history.pushState({}, null, "/login");
      this.$router.push("/login");
      let requestContext = JSON.parse(contextjson);
      let vm = this;
      Auth.loginWithCode(
        requestContext.type,
        requestContext.provider,
        this.$route.query.code,
        window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname,
        (res) => {
          console.log(res);
          if (res.error) {
            alert(res.error);
          } else {
            vm.checkAuth();
          }
        }
      );
    } else {
      this.checkAuth();
    }
  },
};
</script>
<style scoped>
</style>