<template>
  <div class="w-full h-full relative flex flex-col p-4 grow min-w-0">
    <Transition>
      <top-banner :mode="bannerMode" :text="bannerText" v-if="bannerText" />
    </Transition>
    <file-viewer
      v-if="viewFile"
      :file="currentFile"
      @closeFileViewer="viewFile = false"
    />
    <div class="relative">
      <div class="inline-block w-full">
        <span class="font-semibold text-2xl text-gray-700">Finde deinen nächsten Job!</span><br>
        <div v-if="companies.length == 0">
          <span class=" mt-20 center font-bold text-xl" >Aktuell sind keine Jobs verfügbar :/</span>
          </div>
        <div class="pt-10">
          <div v-for="company in companies" :key="company">
            <span class="font-semibold text-3xl text-gray-700">{{company.name}}</span>
                  <div class="inline-block w-full overflow-x-auto">

            <private-job-tile v-for="job in company.jobs" :key="job" @click="this.window.location='/p/'+company.slug+'/?j='+job.id"
              :job="job"
            />
      </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBanner from "@/components/TopBanner.vue";
import FileViewer from "@/components/FileViewer.vue";
import API from "@/API/Private";
import PrivateJobTile from '@/components/tiles/PrivateApplicationTile.vue';
// @ is an alias to /src

export default {
  name: "TenantView",
  components: {
    TopBanner,
    FileViewer,
    PrivateJobTile,
  },
  props: {
    user: Object,
      },
  data() {
    return {
      companies: [],
      viewFile: false,
      bannerText: "",
      window: window
    };
  },

  methods: {
    getCompaniesWithJobs() {
      let vm = this;
      API.getCompaniesWithJobs((companies) => {
        vm.companies = companies;
      });
    },
  },
  created: function () {
    this.getCompaniesWithJobs();
  },
};
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all 4s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
</style>