<template>
  <div ref="modalbg" class="
      z-20
      fixed
      top-0
      left-0
      w-full
      h-full
      sm:pl-56
      lg:pt-24
      pt-20
      text-center
      py-10
      bg-black/50
    " @click="checkClosing($event)">
    <div class="w-full h-full relative">
      <div ref="modalcontent" class="z-40 center w-2/3 text-left bg-white rounded-3xl">
        <div class="w-full h-full relative inline-block">
          <div @click="$emit('closeNotification')" class="iconborder-modal z-50">
            <font-awesome-icon size="lg" icon="xmark" class="inborder" />
          </div>
          <div class="w-full h-full p-8 px-14">
            <div class="w-full text-center">
              <span class="font-bold text-3xl">Was soll mit {{ application.name }} passieren?</span>
              <div class="mt-5">
                <button class="mr-2 bad" @click="confirm('delete')">Löschen</button>
                <button class="negative mr-2" @click="confirm('archive')">
                  Archivieren
                </button>
                <button class="mr-2 positive" @click="confirm('talentpool')">In Talentpool</button>
                <button v-if="mode == 'board'" class="positive" @click="confirm('showAsCanceled')">Als "Abgesagt" anzeigen</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
import API from "@/API/Business";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
    application: Object,
    mode: String,
    cryptKey: String,
  },
  data() {
    return {
    };
  },
  methods: {
    confirm(action) {
      if (action == "archive") {
        API.updateApplication(
          { archived: true, talentpool: false }, this.cryptKey,
          this.application.id,
          () => {
            this.$emit("applicationUpdated", action);
            this.$emit("closeNotification");
          }
        );
      } else if (action == "talentpool") {
        API.updateApplication(
          { archived: true, talentpool: true }, this.cryptKey,
          this.application.id,
          () => {
            this.$emit("applicationUpdated", action);
            this.$emit("closeNotification");
          }
        );
      } else if (action == "showAsCanceled") {
        API.updateApplication(

          { status: "Abgesagt" }, this.cryptKey,
          this.application.id,
          () => {
            this.$emit("applicationUpdated", "status");
            this.$emit("closeNotification");
          }
        );
      } else if (action == "delete") {
        API.deleteApplication(this.application.id,
          () => {
            this.$emit("applicationUpdated", "status");
            this.$emit("closeNotification");
          }
        );
      }
    },
    checkClosing(event) {
      console.log(event);
      if (event.target == this.$refs.modalbg) {
        this.$emit("closeNotification");
      }
    },
  },
  created: function () {

  },
};
</script>

<style scoped></style>
