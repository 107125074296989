<template>
  <div class="tile w-96 p-3 bg-white rounded-2xl text-text-color cursor-pointer" @click="$emit('openInvoice',invoice.id)" >
    <div>
      <div class="inline-block">
        <span style="font-weight: bold; font-size: 25px; white-space: nowrap">{{
          invoice.month
        }}</span>
        <span
          v-bind:class="{
            'text-red-800': invoice.status == 'Fällig',
            'text-green-500': invoice.status == 'Bezahlt',
            'text-yellow-800': invoice.status == 'Offen',
            'bg-red-200': invoice.status == 'Fällig',
            'bg-green-200': invoice.status == 'Bezahlt',
            'bg-yellow-200': invoice.status == 'Offen',
          }"
          class="
            align-middle
            font-bold
            px-2
            rounded-full
            ml-2
          "
          >{{ invoice.status }}</span
        ><br>
        <span style="margin-top: 10px; display: inline-block; font-weight: bold"
          >Betrag: {{ invoice.total }} €</span
        >
      </div>
      <font-awesome-icon
        class="float-right mt-2"
        size="3x"
        icon="file-invoice"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TenantTile",
  props: {
    invoice: Object,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.due,
.undue {
  padding: 1.5px;
  font-weight: bold;
  border-radius: 5px;
  box-sizing: border-box;
  margin-left: 5px;
}
.due {
  border: 3px solid green;
  color: green;
}
.undue {
  border: 3px solid red;
  color: red;
}
</style>
<style scoped>
.firstrow {
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  margin-top: 5px;
}
</style>
