<template>
  <div class="p-3">

    <div id="tile" v-bind:class="[
      application == currentApplication ? 'shadow-selected' : 'shadow-lg',
      application.status == 'Abgesagt' && !application.archived
        ? 'bg-red-100'
        : 'bg-white',
    ]" class="cursor-grab scale-100 p-4 inline-block w-full rounded-xl relative">
      <div class="inborder-none top-2 right-2 absolute cursor-pointer">
        <font-awesome-icon v-if="application.approved" class="text-green-500 text-2xl mr-2" icon="check-circle" />
      </div>
      <div>
        <div v-if="job && job.title == 'Alle Stellen'" class="font-bold -mt-3 mb-2 px-1 py-1 rounded inline-block text-black" :style="{ backgroundColor: application.jobdata.color }"><span class="rounded px-1 bg-white/50">{{
      application.jobdata.title
    }}</span></div>
        <div class="flex flex-row">
          <div v-bind:class="{ 'bg-green-200': application.approved }" class="cursor-pointer rounded-full align-middle relative h-14 w-14 border-primary bg-primary/25 flex-shrink-0">
            <span class="center font-semibold text-xl">{{ initials }}</span>
          </div>
          <div class="ml-3 flex-grow align-middle">
            <span class="text-xl font-medium leading-none break-word">{{
      application.name
    }}</span><br>
            <div v-if="application.mail">
              <span class="text-sm font-medium cursor-pointer leading-none break-all" @click="window.open(`mailto:${application.mail}`, '_blank')">{{
      application.mail
    }}</span>
            </div>
            <div v-if="application.phone">
              <span class="text-sm font-medium cursor-pointer leading-none break-all" @click="window.open(`tel:${application.phone}`, '_blank')">{{
      application.phone
    }}</span>
              <div v-if="!application.encrypted" class="rounded-full float-right bg-primaryLight/25 py-0.5 px-3">
                <span class="text-primary">New</span>
              </div>
            </div>

          </div>
        </div>

      </div>
      <div v-if="application.currentTask" class="px-0.5 inline-block min-w-1/2">
        <font-awesome-icon class="text-secondary" icon="list-check" size="lg" :class="{ '!text-red-500': application.currentTask == user.mail }"/>
        <span class="ml-3 align-middle" :class="{ 'text-red-500 font-extrabold': application.currentTask == user.mail }">{{ application.currentTask.split("@")[0] }}</span>
      </div>
      <div class="px-0.5 inline-block min-w-1/2">
        <font-awesome-icon class="text-secondary" icon="chart-simple" size="lg" :class="{ '!text-red-500': application.daysInStatus > 7 }"/>
        <span class="ml-3 align-middle" :class="{ 'text-red-500 font-extrabold': application.daysInStatus > 7 }">{{ application.daysInStatus }} Tage</span>
      </div>
      <div v-if="fields.startDate" class="px-0.5 inline-block min-w-1/2">
        <font-awesome-icon class="text-secondary" icon="forward" size="lg" />
        <span class="ml-3 align-middle">{{ showDate }}</span>
      </div>
      <div v-if="fields.weekhours && application.weekhours" class="px-0.5 inline-block min-w-1/2">
        <font-awesome-icon class="text-secondary" icon="hourglass-half" size="lg" />
        <span class="ml-3 align-middle">{{ application.weekhours }} h/Woche</span>
      </div>

      <div v-if="fields.birthdate && application.birthdate" class="px-0.5 inline-block min-w-1/2">
        <font-awesome-icon class="text-secondary" icon="address-card" size="lg" />
        <span class="ml-3 align-middle">{{ age }}</span>
      </div>
      <div v-if="fields.city && application.city" class="px-0.5 inline-block min-w-1/2">
        <font-awesome-icon class="text-secondary" icon="street-view" size="lg" />
        <span class="ml-3 align-middle">{{ application.city }}</span>
      </div>
      <div v-if="fields.comments" class="px-0.5 inline-block min-w-1/2 cursor-pointer" @click="$emit('openComments')">
        <font-awesome-icon class="text-secondary" size="lg" icon="message" />
        <span class="ml-4 align-middle">{{ application.comments }}</span>
      </div>
      <div v-if="fields.salary && application.salary" class="px-0.5 inline-block min-w-1/2">
        <font-awesome-icon class="text-secondary" size="lg" icon="coins" />
        <span class="ml-4 align-middle">{{ application.salary }} €</span>
      </div>
      <div class="inline-block min-w-1/2" v-if="job && fields.documents && missing == ''">
        <font-awesome-icon class="text-secondary" size="lg" icon="file-circle-check" />
        <span class="ml-4 align-middle">Vollständig</span>
      </div>
      <div v-if="application.status == 'Interview'" class="px-0.5 inline-block min-w-1/2">
        <font-awesome-icon v-if="!application.confirmed" class="text-red-500" v-bind:class="{
      'text-red-500': !application.scheduled,
      'text-yellow-600': application.scheduled && !application.confirmed,
    }" size="lg" icon="calendar-days" />
        <font-awesome-icon v-else class="text-green-500" size="lg" icon="calendar-check" />
        <span class="ml-4 align-middle" v-bind:class="{
      'text-red-500': !application.scheduled,
      'text-yellow-600': application.scheduled && !application.confirmed,
      'text-green-500': application.scheduled && application.confirmed,
    }">{{ scheduledDate }}</span>
      </div>
      <div v-if="application.status == 'Interview' && scheduledDate2" class="px-0.5 inline-block min-w-1/2">
        <font-awesome-icon v-if="!application.confirmed2" class="text-red-500" v-bind:class="{
      'text-red-500': !application.scheduled2,
      'text-yellow-600': application.scheduled2 && !application.confirmed2,
    }" size="lg" icon="calendar-days" />
        <font-awesome-icon v-else class="text-green-500" size="lg" icon="calendar-check" />
        <span class="ml-4 align-middle" v-bind:class="{
      'text-red-500': !application.scheduled2,
      'text-yellow-600': application.scheduled2 && !application.confirmed2,
      'text-green-500': application.scheduled2 && application.confirmed2,
    }">{{ scheduledDate2 }}</span>
      </div>
      <div class="inline-block min-w-1/2" v-if="fields.documents && missing != ''">
        <font-awesome-icon class="text-primary" size="lg" icon="triangle-exclamation" />
        <span class="ml-4 align-middle">Fehlt:</span><span class="ml-4 align-middle text-primary">{{ missing }}</span>
      </div>
      <div class="w-full text-center mt-2" v-for="comment in application.pinnedComments" :key="comment.id">
        <div class="text-black font-bold rounded-lg bg-red-200 py-0.5 px-3 max-w[95%] xl:max-w-[80%] inline-block">
          <span>{{ comment.message }}</span>
          <span class="text-xs float-right ml-2">{{ comment.time }}</span>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
export default {
  name: "HelloWorld",
  props: {
    application: Object,
    currentApplication: Object,
    fields: Object,
    job: Object,
    user: Object
  },
  data() {
    return {
      window: window
    };
  },
  computed: {
    age: function () {
      let bd = Date.parse(this.application.birthdate + "T00:00");
      if (isNaN(bd)) {
        return "N/A";
      } else {
        return (
          Math.floor((new Date() - bd) / 365 / 24 / 60 / 60 / 1000) + " Jahre"
        );
      }
    },
    showNoteDate: function () {
      let es = Date.parse(this.application.noteDate);
      return isNaN(es)
        ? false
        : new Date(es).toLocaleString("de-DE").slice(0, -3);
    },
    showDate: function () {
      let es = Date.parse(this.application.startDate + "T00:00");
      return isNaN(es)
        ? "Sofort"
        : new Date(es).toLocaleString("de-DE").split(",")[0];
    },
    scheduledDate: function () {
      let es = Date.parse(this.application.scheduled);
      return isNaN(es)
        ? "Kein Termin vereinbart"
        : new Date(es).toLocaleString("de-DE").slice(0, -3);
    },
    scheduledDate2: function () {
      let es = Date.parse(this.application.scheduled2);
      return isNaN(es)
        ? false
        : new Date(es).toLocaleString("de-DE").slice(0, -3);
    },
    scheduledDate3: function () {
      let es = Date.parse(this.application.scheduled3);
      return isNaN(es)
        ? false
        : new Date(es).toLocaleString("de-DE").slice(0, -3);
    },
    initials: function () {
      let nameparts = this.application.name.trim().split(" ");
      if (nameparts == "") {
        return "N/A";
      }
      if (nameparts.length == 1) {
        return nameparts[0][0];
      }
      return nameparts[0][0] + nameparts[nameparts.length - 1][0];
    },
    missing: function () {
      let expected = [];
      if (this.application.jobdata.requiredFields.cover) expected.push("Anschreiben");
      if (this.application.jobdata.requiredFields.cv) expected.push("Lebenslauf");
      if (this.application.jobdata.requiredFields.qualifications) expected.push("Qualifikationen");
      this.application.files.forEach((file) => {
        if (expected.includes(file.type)) {
          expected.splice(expected.indexOf(file.type), 1);
        }
      });
      let r = "";
      expected.forEach((e) => {
        if (r !== "") r += " | ";
        r += e;
      });
      return r;
    },
  },
  created: function () {
  },
};
</script>