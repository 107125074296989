<template>
  <div
    ref="modalbg"
    class="
      z-30
      absolute
      top-0
      left-0
      w-full
      h-full
      pl-20
      text-center
      py-10
      bg-black/50
    "
    @click="checkClosing($event)"
  >
    <div class="w-full h-full relative">
      <div
        ref="modalcontent"
        class="z-40 center w-1/2 text-left bg-white rounded-3xl"
      >
        <div class="w-full h-full relative inline-block">
          <div
            @click="$emit('closeNotification')"
            class="iconborder-modal z-50"
          >
            <font-awesome-icon size="lg" icon="xmark" class="inborder" />
          </div>
          <div class="w-full h-full p-8 px-14">
            <div class="w-full text-center">
              <span v-if="mode == 'cancel'" class="font-bold text-3xl"
                >Hast du {{ application.name }} abgesagt?</span
              >
              <span v-else class="font-bold text-3xl"
                >Möchtest du {{ application.name }} wirklich löschen?</span
              >
              <div class="mt-5">
                <button class="mr-5" @click="$emit('closeNotification')">Nein</button>
                <button
                  v-bind:class="{ bad: mode == 'delete',negative: mode=='cancel' }"
                  @click="confirm()"
                >
                  Ja
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input
        type="file"
        id="newApplicationFileUpload"
        @change="prepareUploadFile()"
        hidden
      />
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
import API from "@/API/Business";

export default {
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  props: {
    application: Object,
    mode: String,
    cryptKey: String,
  },
  data() {
    return {
    };
  },
  methods: {
    confirm(event) {
      if (this.mode == "cancel") {
        API.updateApplication(
          { status: "Abgesagt" },this.cryptKey,
          this.application.id,
          () => {
            this.$emit("applicationUpdated");
            this.$emit("closeNotification");
          }
        );
      } else if (this.mode == "delete") {
        API.deleteApplication(this.application.id, (s) => {
          this.$emit("applicationUpdated", "deleted");
          this.$emit("closeNotification");
        });
      }
    },
    checkClosing(event) {
      console.log(event);
      if (event.target == this.$refs.modalbg) {
        this.$emit("closeNotification");
      }
    },
  },
  created: function () {
  },
};
</script>

<style scoped>
</style>
